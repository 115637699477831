import * as easings from "d3-ease";

import { PoseableBox, PoseableHeading } from "components/poseable";

import { BigLink } from "components/BigPageLink";
import Img from "components/Img";
import PinchZoom from "components/PinchZoom";
import React from "react";
import { fadeSlide } from "components/poses";
import posed from "react-pose";
import styled from "styled-components";

const CarouselItemContent = styled(
  posed(PoseableBox)({
    current: { staggerChildren: 100 },
    prev: { staggerChildren: 50, staggerDirection: -1 },
    next: { staggerChildren: 50, staggerDirection: -1 },
  })
)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const headingPoseProps = {
  current: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 300,
      type: "tween",
      duration: 300,
      ease: easings.easeSinOut,
    },
  },
  prev: {
    opacity: 0,
    y: 10,
    transition: {
      type: "tween",
      duration: 200,
      ease: easings.easeSinIn,
    },
  },
  next: {
    opacity: 0,
    y: 10,
    transition: {
      type: "tween",
      duration: 200,
      ease: easings.easeSinIn,
    },
  },
};

const CarouselItemHeading = posed(PoseableHeading)(headingPoseProps);
const CarouselItemLinkWrapper = posed.div(headingPoseProps);
const Fade = posed.div(fadeSlide);

const renderContent = ({
  item,
  idx,
  currentIndex,
  theme,
  onLoad,
  triggerLoad = false,
  focusHandler = null,
  tabIndex = null,
}) => {
  let alt = item.image.alt || item.heading;

  return (
    <React.Fragment>
      <Img
        Tag="figure"
        {...item.image}
        alt={alt}
        onLoad={onLoad}
        triggerLoad={triggerLoad}
      />
      <CarouselItemContent
        px={[20, 40, 60, 80, 100]}
        initialPose={`next`}
        pose={
          idx === currentIndex
            ? "current"
            : idx < currentIndex
            ? "prev"
            : "next"
        }
        withParent={false}
      >
        <CarouselItemHeading
          is="h3"
          color={item.theme === "light" ? "white" : theme.textColor}
          fontSize={[12, 14]}
          m={0}
          fontFamily="Calibre"
          fontWeight="bold"
          style={{
            userSelect: `none`,
            position: `relative`,
            display: `inline-block`,
            flex: `none`,
            alignSelf: `flex-start`,
          }}
        >
          {item.category}
        </CarouselItemHeading>
        <CarouselItemLinkWrapper>
          <BigLink
            link={item.link}
            label={item.heading}
            color={item.theme === "light" ? "white" : theme.textColor}
            fontSize={[50, 60, 80, 90, 100, 120]}
            focusHandler={focusHandler}
            tabIndex={tabIndex}
            style={{
              textAlign: `left`,
              margin: 0,
              flex: `none`,
              alignSelf: `flex-start`,
            }}
          />
        </CarouselItemLinkWrapper>
      </CarouselItemContent>
    </React.Fragment>
  );
};

export default ({
  item,
  idx,
  currentIndex,
  theme,
  reveal = null,
  onLoad = null,
  ioMargin = undefined,
  triggerLoad = false,
  focusHandler = null,
  tabIndex = null,
}) => {
  if (!reveal) {
    return renderContent({
      item,
      idx,
      currentIndex,
      theme,
      onLoad,
      ioMargin,
      triggerLoad,
      focusHandler,
      tabIndex,
    });
  }
  switch (reveal) {
    case `zoom`:
      return (
        <PinchZoom revealColor={theme.revealColor}>
          {renderContent({
            item,
            idx,
            currentIndex,
            theme,
            onLoad,
            ioMargin,
            triggerLoad,
            focusHandler,
            tabIndex,
          })}
        </PinchZoom>
      );
    case `fade`:
    default:
      return (
        <Fade visibledelay={1000} offset={30}>
          {renderContent({
            item,
            idx,
            currentIndex,
            theme,
            onLoad,
            ioMargin,
            triggerLoad,
            focusHandler,
            tabIndex,
          })}
        </Fade>
      );
  }
};

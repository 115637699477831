import React from "react";
import { Section, Container } from "components/layout";
import BigLogo from "components/icons/BigLogo";
import { Box } from "@rebass/grid";
import PageHeading from "components/PageHeading";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  @media screen and (min-width: 800px) and (max-height: 1000px) {
    margin-top: -50px;
  }
  @media screen and (min-width: 800px) and (max-height: 800px) {
    margin-top: -80px;
  }
`;

export default ({ theme, ready }) => (
  <Section top big>
    <StyledContainer>
      <Box
        width={[`100%`, `90%`, `80%`, `70%`, `65%`]}
        mx={`auto`}
        mb={0}
        mt={0}
      >
        <BigLogo style={{ display: "block", width: `100%` }} />
      </Box>
      <PageHeading
        standalone={true}
        ready={ready}
        heading={`FOR THE\nCHALLENGERS`}
        headingProps={{
          color: theme.colors.black,
          mt: 0,
          fontSize: [
            `calc(13 * (1vw + 1vh - 1vmin))`,
            `calc(13 * (1vw + 1vh - 1vmin))`,
            `calc(16 * (1vw + 1vh - 1vmin))`,
            `calc(16 * (1vw + 1vh - 1vmin))`,
            `calc(16 * (1vw + 1vh - 1vmin))`,
          ],
        }}
        style={{
          position: `absolute`,
          top: `50%`,
          left: `50%`,
          transform: `translateX(-50%) translateY(-50%) translateZ(0)`,
        }}
      />
    </StyledContainer>
  </Section>
);

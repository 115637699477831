import React from "react";
import SectionAside from "components/typography/SectionAside";
import Content from "components/typography/Content";
import TextButtonWithArrow from "components/buttons/TextButtonWithArrow";
import { Container, Row, Column, PosedSection } from "components/layout";

export default ({
  id,
  headingNode,
  subheadingNode,
  aboutButtonLabel,
  theme,
}) => (
  <PosedSection big>
    <Container>
      <Row>
        <SectionAside num="01." label={"Agency"} />
        <Column width={[1, 1, 1, 10 / 12]}>
          <Content
            posed={true}
            visibledelay={200}
            mb={[20, 30, 40, 45, 50, 55]}
            first
            size={"big"}
            fontFamily={"Larish"}
            fontWeight={"semibold"}
            css={`
              & strong {
                color: ${theme.colors.red};
              }
            `}
            dangerouslySetInnerHTML={{
              __html: headingNode.childMarkdownRemark.html,
            }}
          />
          <Content
            posed={true}
            visibledelay={300}
            mb={[40, 60, 80, 90, 100, 110]}
            dangerouslySetInnerHTML={{
              __html: subheadingNode.childMarkdownRemark.html,
            }}
          />
          <TextButtonWithArrow
            visibledelay={400}
            label={aboutButtonLabel}
            url="/agency"
          />
        </Column>
      </Row>
    </Container>
  </PosedSection>
);

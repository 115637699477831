import * as easings from "d3-ease";

import { Column, Container, PosedSection, Row } from "components/layout";

import Content from "components/typography/Content";
import Img from "components/Img";
import { PoseableColumn } from "components/poseable";
import React from "react";
import SectionAside from "components/typography/SectionAside";
import TextButtonWithArrow from "components/buttons/TextButtonWithArrow";
import { byUrl } from "utils/resolveLink";
import { fadeUp } from "components/poses";
import newlineToBr from "utils/newlineToBr";
import posed from "react-pose";

const PosedColumn = posed(PoseableColumn)({
  ...fadeUp,
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      ease: easings.easeSinOut,
      duration: 250,
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: {
      type: "tween",
      ease: easings.easeSinIn,
      duration: 200,
    },
  },
});

export default ({
  heading,
  subheading,
  featuredClients,
  featuredClientsButtonLabel,
}) => (
  <PosedSection>
    <Container>
      <Row>
        <SectionAside num="02." label={"Work"} />
        <Column width={[1, 1, 1, 10 / 12]}>
          <Content
            posed={true}
            visibledelay={200}
            mb={[30, 30, 40, 50, 60]}
            fontFamily={"Druk"}
            fontSize={[50, 60, 80, 100, 120, 160]}
            lineHeight={`1em`}
            first
          >
            {newlineToBr(heading)}
          </Content>
          <Content
            posed={true}
            visibledelay={300}
            mb={`0px`}
            fontSize={[18, 24, 30]}
            fontFamily="Larish"
            width={[`100%`, `70%`, `60%`, `60%`, `60%`, `40%`]}
          >
            {newlineToBr(subheading)}
          </Content>
        </Column>
      </Row>
      {featuredClients && (
        <Row
          id="client-grid"
          pt={[30, 30, 40, 50, 60]}
          pb={[30, 30, 40, 50, 60]}
        >
          {featuredClients.map(({ logo, name }, idx) => {
            return (
              <PosedColumn
                key={`client-${idx}`}
                width={[1 / 2, 1 / 2, 1 / 4]}
                mb={[2, 3]}
                visibledelay={400 + idx * 100}
              >
                <Img alt={name} {...logo} />
              </PosedColumn>
            );
          })}
          <PosedColumn
            mt={[30, 30, 40, 50, 60]}
            ml={[`0px`, `0px`, `${(1 / 12) * 100}%`]}
          >
            <TextButtonWithArrow
              visibledelay={400 + featuredClients.length * 100 + 200}
              label={featuredClientsButtonLabel}
              url={byUrl("/work")}
              anchor={`clients`}
              title={featuredClientsButtonLabel}
            />
          </PosedColumn>
        </Row>
      )}
    </Container>
  </PosedSection>
);

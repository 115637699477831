import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { withTheme } from "styled-components";
import HomeHero from "components/home/HomeHero";
import AgencyBlock from "components/home/AgencyBlock";
import WorkBlock from "components/home/WorkBlock";
import Carousel from "components/Carousel";
import Footer from "components/Footer";
import HeroItemRenderer from "components/carousel-item-renderers/HeroItemRenderer";
import BaseTemplate from "templates/base";

const renderBlock = (block, theme) => {
  const apiKey = block.model.apiKey;
  let Cmp = null;
  let extras = {};
  switch (apiKey) {
    case "agency_block":
      Cmp = AgencyBlock;
      extras = { theme };
      break;
    case "work_block":
      Cmp = WorkBlock;
      break;
    case "carousel":
      Cmp = Carousel;
      extras = { ItemRenderer: HeroItemRenderer, big: true };
      break;
    default:
      break;
  }
  if (Cmp) {
    return <Cmp key={block.id} {...block} {...extras} />;
  }

  return null;
};

class HomeTemplate extends BaseTemplate {
  render() {
    const { ready } = this.state;
    const { data, theme } = this.props;
    const { page } = data;
    const { seoMetaTags, body } = page;
    return (
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <link rel="preload" as="image" href="/loader-pink.gif" />
        </HelmetDatoCms>
        <HomeHero theme={theme} ready={ready} />
        {body && body.map((block) => renderBlock(block, theme))}
        <Footer theme={theme} globals={this.props.pageContext.globals} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    page: datoCmsHomePage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      body {
        ... on DatoCmsAgencyBlock {
          model {
            apiKey
          }
          id
          headingNode {
            childMarkdownRemark {
              html
            }
          }
          subheadingNode {
            childMarkdownRemark {
              html
            }
          }
          aboutButtonLabel
        }
        ... on DatoCmsWorkBlock {
          model {
            apiKey
          }
          id
          heading
          subheading
          featuredClients {
            ... on DatoCmsClient {
              name
              logo {
                url
                alt
                fluid(
                  maxWidth: 324
                  imgixParams: { auto: "compress,format", fm: "jpg", q: 75 }
                ) {
                  ...GatsbyDatoCmsFluid_noBase64
                }
              }
            }
          }
          featuredClientsButtonLabel
        }
        ... on DatoCmsCarousel {
          model {
            apiKey
          }
          id
          sideLabel
          items {
            id
            theme
            image {
              url
              alt
              fluid(
                maxWidth: 1135
                imgixParams: { auto: "compress,format", fm: "jpg", q: 75 }
              ) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
            link {
              id
              url
              title
            }
            heading
            category
          }
          showLink
          link
          linkLabel
        }
      }
    }
  }
`;

export default withTheme(HomeTemplate);
